/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "G-3RCJTR2JTF",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Shubham",
  logo_name: "<Shubham />",
  nickname: "shubham",
  full_name: "Shubham Mathur",
  subTitle: "Full Stack Developer, Blockchain Enthusiast 🔥. Always learning.",
  resumeLink:
    "https://app.enhancv.com/share/ac03c824?utm_medium=growth&utm_campaign=share-resume&utm_source=dynamic",
  mail: "mailto:itseasy21@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/itseasy21",
  linkedin: "https://www.linkedin.com/in/itseasy21/",
  gmail: "itseasy21@gmail.com",
  gitlab: "https://gitlab.com/itseasy21",
  facebook: "https://www.facebook.com/itseasy21/",
  twitter: "https://twitter.com/itseasy21",
  instagram: "https://www.instagram.com/itseasy21/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
        "⚡ Building responsive website front end using React, Next.js, and TypeScript",
        "⚡ Developing mobile applications using React Native, Expo, and Android",
        "⚡ Creating application backend in Python, Node.js, and Firebase",
        "⚡ Integration of third party services such as Firebase, AWS, and cloud technologies",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#007ACC",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Next.js",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "Solana",
          fontAwesomeClassname: "simple-icons:solana",
          style: {
            color: "#9945FF",
          },
        },
        {
          skillName: "Expo",
          fontAwesomeClassname: "simple-icons:expo",
          style: {
            color: "#000020",
          },
        },
      ],
    },
    {
      title: "Blockchain Development",
      fileName: "BlockchainImg",
      skills: [
        "⚡ Experience in developing Smart Contracts and DApps",
        "⚡ Building NFT marketplaces and applications on multiple chains",
        "⚡ Integrating with Ethereum, Solana, and Immutable X ecosystems",
        "⚡ Working with wallets, RPC nodes, and blockchain APIs",
      ],
      softwareSkills: [
        {
          skillName: "Ethereum",
          fontAwesomeClassname: "simple-icons:ethereum",
          style: {
            color: "#3C3C3D",
          },
        },
        {
          skillName: "Solana",
          fontAwesomeClassname: "simple-icons:solana",
          style: {
            color: "#9945FF",
          },
        },
        {
          skillName: "IPFS",
          fontAwesomeClassname: "simple-icons:ipfs",
          style: {
            color: "#65C2CB",
          },
        },
        {
          skillName: "Web3.js",
          fontAwesomeClassname: "simple-icons:web3dotjs",
          style: {
            color: "#F16822",
          },
        },
        {
          skillName: "Ethers.js",
          fontAwesomeClassname: "simple-icons:ethereum",
          style: {
            color: "#3C3C3D",
          },
        },
        {
          skillName: "Solidity",
          fontAwesomeClassname: "simple-icons:solidity",
          style: {
            color: "#363636",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms (AWS, Google Cloud, Azure)",
        "⚡ Building serverless applications with AWS Lambda and DynamoDB",
        "⚡ Managing data storage with S3, Firebase, and NoSQL databases",
        "⚡ Experience with CI/CD and containerization",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#2088FF",
          },
        },
        {
          skillName: "AWS Lambda",
          fontAwesomeClassname: "simple-icons:awslambda",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "AWS S3",
          fontAwesomeClassname: "simple-icons:amazons3",
          style: {
            color: "#569A31",
          },
        },
        {
          skillName: "AWS DynamoDB",
          fontAwesomeClassname: "simple-icons:amazondynamodb",
          style: {
            color: "#4053D6",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "RMIT University",
      subtitle: "Master of Information Technology",
      logo_path: "rmit.png",
      alt_name: "RMIT",
      duration: "2020 - 2021",
      descriptions: [
        // "⚡ I'm currently pursuing my bachelors in Information Technology."
        "⚡ I have studied advance subjects like Software Management, Usability, Algorithms, Cloud Security, etc.",
        "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://rmit.edu.au/",
    },
    {
      title: "Patel College of Science & Technology",
      subtitle: "Bachelor of Information Technology",
      logo_path: "patelcollege.png",
      alt_name: "PCST",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "https://www.patelcollege.com/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Winner @ Holon Innovate 2021",
      subtitle: "Holon Global Investments",
      logo_path: "holon.png",
      certificate_link:
        "https://www.linkedin.com/feed/update/urn:li:activity:6869834381620588545/",
      alt_name: "Holon Global Investments",
      color_code: "#262261",
    },
    {
      title: "HEX5.2 Discovery SPRINT Program",
      subtitle: "Hacker Exchange",
      logo_path: "hex.gif",
      certificate_link:
        "https://www.credential.net/36196e5d-bd74-486c-a2a3-716ddbdcc669",
      alt_name: "Hacker Exchange",
      color_code: "#000",
    },
    {
      title: "Peer Mentoring",
      subtitle: "RMIT",
      logo_path: "rmit.png",
      certificate_link:
        "https://www.credly.com/badges/f4c24ead-85e4-49d8-8ddf-b56a9f51882e?source=linked_in_profile",
      alt_name: "RMIT",
      color_code: "#fff",
    },
    {
      title: "Python Data Structures",
      subtitle: "University of Michigan",
      logo_path: "uni-michigan.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/29M2VWZCM4BU",
      alt_name: "University of Michigan",
      color_code: "#00457C",
    },
    {
      title: "Programming for Everybody (Getting Started with Python)",
      subtitle: "University of Michigan",
      logo_path: "uni-michigan.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/Y3PGH7DVNCYK",
      alt_name: "University of Michigan",
      color_code: "#00457C",
    },
    {
      title: "Using Python to Access Web Data",
      subtitle: "University of Michigan",
      logo_path: "uni-michigan.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/H6X7KFEVBH36",
      alt_name: "University of Michigan",
      color_code: "#00457C",
    },
    {
      title: "PHP with MySQL",
      subtitle: "Naresh i Technologies",
      logo_path: "nareshit.png",
      certificate_link: "#",
      alt_name: "Naresh i Technologies",
      color_code: "#47A048",
    },
    {
      title: "Android Development",
      subtitle: "Naresh i Technologies",
      logo_path: "nareshit.png",
      certificate_link: "#",
      alt_name: "Naresh i Technologies",
      color_code: "#47A048",
    },
    {
      title: "Finalist @ SIH2017",
      subtitle: "Smart India Hackathon 2017",
      logo_path: "sih2017.png",
      certificate_link: "#",
      alt_name: "Smart India Hackathon 2017",
      color_code: "#fff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed two internships and have a professional experience of 4+ Years. I've been working in the IT Industry since 2014, freelancing on various projects. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Directed Electric Australia",
          company_url: "https://www.directed.com.au/",
          logo_path: "directed.png",
          duration: "Feb 2022 - Present",
          location: "Office",
          description: `Operating from 3 main divisions; OEM, Telematics & Retail, Directed partner with leading global brands to bring product to market across leading National Retailers, Specialist Chains, Independent Stores, plus an extensive network of trade and OEM customers.
          ⚈ Working on the current next-generation telematics platform build and evolution to a large scale IoT platform. In addition, occasional maintenance of various operational tools used to support the previous generation of Telematics and IoT platforms, including internal & customer-facing portals.
          ⚈ Working with teams globally around Australia (Melbourne, Brisbane), Hungary & New Zealand to falicitate front-end and backend development.
          ⚈ Working on tech stack ranging from ReactJS, Express APIs, AWS, i18n (locize), CI/CD Pipeline
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Software Developer",
          company: "Career Success Australia",
          company_url: "https://careersuccessaustralia.com.au/",
          logo_path: "csa.png",
          duration: "Feb 2021 - Feb 2022",
          location: "Office",
          description: `Career Success Australia offers Career Coaching, Career Counselling & Internships in Australia.
          ⚈ Developed Job Search Portal
          ⚈ Lead team of 4 interns for website & team of 2 for marketing & content generation
          ⚈ Optimized the back-end WordPress and lowered the service request time from 700ms to 150ms, and improve website speed by 100%
          ⚈  Connected the web with Zoho CRM and implemented workflows
          ⚈ Worked in a team of 10 people
          ⚈ Collaborated with SEO Expert to implement new techniques and boost websites SEO Presence & ranked website on Page #1 on Google
          ⚈ Train, manage and provide guidance to junior software development staff.
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Software Developer",
          company: "Tokom Software Pvt. Ltd.",
          company_url: "https://tokomsoft.com/",
          logo_path: "tokom.png",
          duration: "July 2017 - Dec 2019",
          location: "Office",
          description: `⚈ Delivered configuration management tools to track server settings for performance testing which saved 25% of initial machine setup
          ⚈ Reduced major clients' database size by nearly 50% and improved the performance
          ⚈ Worked with team of 10 members and provided end-to-end solution  for clients
          ⚈ Contributed to the development of over 10 web and mobile applications
          ⚈ Experience in implementing 3rd party frameworks
          ⚈ Developed and tested hybrid and native android application for clients
          ⚈ Designed and developed 2+ micro-services
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Internship",
      experiences: [
        {
          title: "Web Developer",
          company: "Career Success Australia",
          company_url: "https://careersuccessaustralia.com.au/",
          logo_path: "csa.png",
          duration: "Nov 2020 - Feb 2021",
          location: "Office",
          description: " ",
          color: "#4285F4",
        },
        {
          title: "PHP Developer",
          company: "Tokom Software Pvt. Ltd.",
          company_url: "https://tokomsoft.com/",
          logo_path: "tokom.png",
          duration: "Apr 2017 - July 2017",
          location: "Office",
          description: " ",
          color: "#196acf",
        },
        {
          title: "GitHub Student Developer",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Nov 2018 - Present",
          location: "Work from Home",
          description:
            "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },
        {
          title: "Google Local Guide",
          company: "Google Map",
          company_url: "https://maps.google.com/localguides/",
          logo_path: "localguide.png",
          duration: "Sep 2018 - Present",
          location: "Work From Home",
          description:
            "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
          color: "#D83B01",
        },
      ],
    },
    {
      title: "Publications",
      experiences: [
        {
          title:
            "A computational study and meta-analysis of content based image retrieval",
          company:
            "International Journal of Advanced Technology and Engineering Exploration (IJATEE)",
          company_url: "http://bit.ly/2FIToSU",
          logo_path: "accentsJournal.png",
          duration: "Jan 2019",
          location: " ",
          description: " ",
          color: "#4285F4",
        },
        {
          title:
            "A methodological study and analysis of machine learning algorithms",
          company:
            "International Journal of Advanced Technology and Engineering Exploration (IJATEE)",
          company_url: "http://bit.ly/2OyncV1",
          logo_path: "accentsJournal.png",
          duration: "Feb 2019",
          location: " ",
          description: " ",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My experience ranges between PHP Websites, Python Application, NodeJS Backend Projects, Android Applications, Python Scripts, and React Project. I also have vast experience of SEO & SMO. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "shubham.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently now but when I do something awesome, I do try to document it so it can be helpful to others. My old blog can be found here.",
    link: "https://w3tool.blogspot.com/",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Discord Mint Bot",
      url: "https://github.com/itseasy21/discord-mint-bot",
      description:
        "A Discord bot allowing users to view their NFTs and generate AI videos from them. Supports viewing minted NFTs from collections, generating AI videos, tracking credits, and providing wallet management APIs.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "AWS",
          iconifyClass: "vscode-icons:file-type-aws",
        },
        {
          name: "DynamoDB",
          iconifyClass: "logos:aws-dynamodb",
        },
        {
          name: "S3",
          iconifyClass: "logos:aws-s3",
        },
        {
          name: "AI",
          iconifyClass: "carbon:machine-learning-model",
        },
        {
          name: "Discord",
          iconifyClass: "logos:discord-icon",
        },
      ],
    },
    {
      id: "1",
      name: "The ARK",
      url: "https://github.com/itseasy21/TheArk-Web",
      description:
        "A monorepo containing multiple blockchain web applications with Yarn workspaces. Includes user-facing app, admin interface, and shared components library for NFT management and cryptocurrency transactions.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Next.js",
          iconifyClass: "logos:nextjs-icon",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescriptdef-official",
        },
        {
          name: "Ethers.js",
          iconifyClass: "file-icons:ethereum",
        },
        {
          name: "Firebase",
          iconifyClass: "logos:firebase",
        },
        {
          name: "Chakra UI",
          iconifyClass: "simple-icons:chakraui",
        },
      ],
    },
    {
      id: "2",
      name: "Savannah Legends",
      url: "https://github.com/itseasy21/theark-rhino",
      description:
        "A Next.js-based web application that integrates with the Solana blockchain for NFT minting, collections, and statistics. Features comprehensive NFT operations with wallet adapters and Metaplex tools.",
      languages: [
        {
          name: "Next.js",
          iconifyClass: "logos:nextjs-icon",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescriptdef-official",
        },
        {
          name: "Solana",
          iconifyClass: "cryptocurrency:sol",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "SASS",
          iconifyClass: "logos:sass",
        },
        {
          name: "AWS Lambda",
          iconifyClass: "logos:aws-lambda",
        },
      ],
    },
    {
      id: "3",
      name: "Mindful Mama Tribe App",
      url: "https://github.com/itseasy21/mama-project",
      description:
        "A React Native mobile app for mothers and caregivers featuring subscription management, chat functionality, content sharing, and community features. Cross-platform for iOS and Android.",
      languages: [
        {
          name: "React Native",
          iconifyClass: "logos:react",
        },
        {
          name: "Expo",
          iconifyClass: "logos:expo-icon",
        },
        {
          name: "Firebase",
          iconifyClass: "logos:firebase",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "OneSignal",
          iconifyClass: "simple-icons:onesignal",
        },
        {
          name: "Formik",
          iconifyClass: "logos:formik",
        },
      ],
    },
    {
      id: "4",
      name: "DecentraBox",
      url: "https://github.com/itseasy21/DecentraBox",
      description:
        "With DecentraBox files aren't stored in centralized data centers— instead, they're encrypted, split into pieces, and distributed on a filecoin+ipfs network.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescriptdef-official",
        },
        {
          name: "IPFS",
          iconifyClass: "simple-icons:ipfs",
        },
      ],
    },
    {
      id: "5",
      name: "Momento",
      url: "https://momento.fan",
      description:
        "Memento is an NFT marketplace infrastructure dedicated to providing an opportunity for fans to access and store unique digital experiences of their favourite band.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescriptdef-official",
        },
        {
          name: "IPFS",
          iconifyClass: "simple-icons:ipfs",
        },
        {
          name: "Immutable X",
          iconifyClass: "icon-park:blockchain",
        },
        {
          name: "Solidity",
          iconifyClass: "file-icons:solidity",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "6",
      name: "ShopWise",
      url: "#",
      description:
        'We have developed "ShopWise", an Expense Tracker Application to provide users with a simple and Interactiveactive UI to help track their spending easily and quickly.',
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos:php",
        },
        {
          name: "AWS",
          iconifyClass: "vscode-icons:file-type-aws",
        },
        {
          name: "DynamoDB",
          iconifyClass: "logos:aws-dynamodb",
        },
      ],
    },
    {
      id: "7",
      name: "Coin-Tracker",
      url: "https://github.com/itseasy21/Coin-Tracker",
      description:
        "A basic CoinGecko API Integration with React w/ Typescript. Shows Global Market Information, Trending Coins, and Overall Coins with filters.",
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos:php",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescriptdef-official",
        },
      ],
    },
    {
      id: "8",
      name: "Kungfluz",
      url: "https://kungfluz.com",
      description:
        "Influencer Platform which gives business the flexibility run targeted marketing campaigns by connecting them with certified social media influencers.",
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos:php",
        },
        {
          name: "AWS",
          iconifyClass: "vscode-icons:file-type-aws",
        },
        {
          name: "CodeIgnitor",
          iconifyClass: "ant-design:code-outlined",
        },
      ],
    },
    {
      id: "9",
      name: "S&E Real Estate",
      url: "https://github.com/itseasy21/S-E-Real-Estate",
      description:
        "A Full System for a Real Estate Agency Built-in JAVA, allowing operations for customers to book/rent a property and for an admin to manage the bookings.",
      languages: [
        {
          name: "Java",
          iconifyClass: "cib:java",
        },
        {
          name: "MySQL",
          iconifyClass: "cib:mysql",
        },
      ],
    },
    {
      id: "10",
      name: "Zombie Network",
      url: "https://github.com/itseasy21/zombie_network",
      description:
        "A network of Telegram users accounts and bots to track, influence and monitor Telegram Users and communities within the native application.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "11",
      name: "InstaBot",
      url: "https://github.com/itseasy21/InstaBot",
      description:
        "A Personal Portfolio Website that showcases my work and experience. which is hosted on Github Pages.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
